var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-dataTableInternalPartProduction"
  }, [_c('v-data-table', {
    staticClass: "data-table-production",
    attrs: {
      "search": _vm.searchField,
      "custom-filter": _vm.customSearch,
      "headers": _vm.headersMain,
      "items": _vm.partsFiltered,
      "expanded": _vm.expandedRows,
      "item-key": "keyExpand",
      "show-expand": "",
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    on: {
      "update:expanded": function updateExpanded($event) {
        _vm.expandedRows = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.printer",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "x-small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-print ")]), _c('span', [_vm._v(" " + _vm._s(item.printerBrand.replace('_', ' ')) + " - " + _vm._s(item.printerModel.replace('_', ' ')) + " ( ")]), _c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(_vm.$t(item.material)))]), _c('span', [_vm._v(")")])];
      }
    }, {
      key: "item.order",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "x-small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-sticky-note ")]), item.order !== '' ? _c('span', [_vm._v(_vm._s(item.order))]) : _c('span', {
          staticClass: "font-weight-light"
        }, [_vm._v("- " + _vm._s(_vm.$t('None')))])];
      }
    }, {
      key: "item.quantity",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "x-small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-cubes ")]), _c('span', [_vm._v(_vm._s(item.quantity))])];
      }
    }, {
      key: "item.created",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "x-small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-calendar-alt ")]), item.created ? _c('span', [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "numeric")) + " ")]) : _c('span', {
          staticClass: "font-weight-light"
        }, [_vm._v("- " + _vm._s(_vm.$t("Unknown")))])];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref5) {
        var headers = _ref5.headers,
            item = _ref5.item;
        return [_c('td', {
          staticClass: "pa-0",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-data-table', {
          staticClass: "sub-table",
          attrs: {
            "headers": _vm.headersSub,
            "hide-default-footer": "",
            "items-per-page": -1,
            "footer-props": {
              itemsPerPageOptions: [15, 50, 100, -1]
            },
            "items": item.jobs
          },
          scopedSlots: _vm._u([{
            key: "item.created",
            fn: function fn(_ref6) {
              var item = _ref6.item;
              return [_c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "x-small": "",
                  "color": "info"
                }
              }, [_vm._v(" fas fa-calendar-alt ")]), item.created ? _c('span', [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "numeric")) + " ")]) : _c('span', {
                staticClass: "font-weight-light"
              }, [_vm._v("- " + _vm._s(_vm.$t("Unknown")))])];
            }
          }, {
            key: "item.jobNumber",
            fn: function fn(_ref7) {
              var item = _ref7.item;
              return [item.jobNumber > 0 ? _c('span', [_vm._v(_vm._s(item.jobNumber))]) : _c('span', {
                staticClass: "font-weight-light"
              }, [_vm._v(_vm._s(_vm.$t('OnHold')))])];
            }
          }, {
            key: "item.quantity",
            fn: function fn(_ref8) {
              var item = _ref8.item;
              return [_c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "x-small": "",
                  "color": "info"
                }
              }, [_vm._v(" fas fa-cubes ")]), _c('span', [_vm._v(_vm._s(item.quantity))])];
            }
          }, {
            key: "item.status",
            fn: function fn(_ref9) {
              var item = _ref9.item;
              return [_c('div', {
                staticClass: "d-flex align-center justify-center"
              }, [_c('v-tooltip', {
                attrs: {
                  "top": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref10) {
                    var on = _ref10.on;
                    return [item.status === 'PLANNING' ? _c('v-img', _vm._g({
                      staticClass: "align-center",
                      attrs: {
                        "src": require("../../../../public/img/icons/PlanningDark.png"),
                        "alt": "planning dark",
                        "max-height": "24px",
                        "max-width": "24px"
                      }
                    }, on)) : _vm._e(), item.status === 'PRODUCTION' ? _c('v-img', _vm._g({
                      staticClass: "align-center",
                      attrs: {
                        "src": require("../../../../public/img/icons/icon3DprinterDark.png"),
                        "alt": "icon3D printer dark",
                        "max-height": "32px",
                        "max-width": "32px"
                      }
                    }, on)) : _vm._e(), item.status === 'WAITING' ? _c('v-img', _vm._g({
                      staticClass: "align-center",
                      attrs: {
                        "src": require("../../../../public/img/icons/NeedsNestingDark.png"),
                        "alt": "needs nesting dark",
                        "max-height": "32px",
                        "max-width": "32px"
                      }
                    }, on)) : _vm._e()];
                  }
                }], null, true)
              }, [_c('span', [_vm._v(_vm._s(_vm.$t(item.status)))])])], 1)];
            }
          }, {
            key: "item.link",
            fn: function fn(_ref11) {
              var itemChild = _ref11.item;
              return [_c('v-btn', {
                attrs: {
                  "small": "",
                  "color": "info",
                  "href": _vm.getHRef(item, itemChild)
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" fas fa-external-link-square-alt ")])], 1)];
            }
          }], null, true)
        })], 1)];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }