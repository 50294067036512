<template lang="html" src="./supplierInternalPartProduction.template.vue">
  <!-- -->
</template>

<style lang="scss" src="./supplierInternalPartProduction.scss"></style>

<script>
import { EventBus } from '@cloudmanufacturingtechnologies/portal-components';
import DataTableInternalPartProduction from './dataTableInternalPartProduction/DataTableInternalPartProduction';
import DataTableInternalPartArchive from './dataTableInternalPartArchive/DataTableInternalPartArchive';

const i18nData = require('./supplierInternalPartProduction.i18n');

export default {
  name: 'SupplierInternalPartProduction',
  components: {
    DataTableInternalPartProduction,
    DataTableInternalPartArchive,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    supplierUUID: {
      type: String,
      required: true,
    },
    supplierPartUUID: {
      type: String,
      default: null,
    },
    warehousePartUUID: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      searchField: null,
      panels: [0, 1, 2],
      currentSortAscending: false,
      planningAndProductionParts: [],
      planningAndProductionPartsFiltered: [],
      planningAndProductionPartsExpanded: [],
      productionInformations: null,
      doneParts: [],
      donePartsByJob: [],
      donePartsFiltered: [],
      donePartsByJobFiltered: [],
      donePartsExpanded: [],
      allTechnologies: [],
      allMaterials: [],
      formTechnologies: [],
      formMaterials: [],
      formMenuDateMin: false,
      formMenuDateMax: false,
      formDateMin: null,
      formDateMax: null,
      formattedDateMin: null,
      formattedDateMax: null,
      archiveToggleButton: 1,
    };
  },
  created() {
    EventBus.$on(
      'SupplierInternalPartSentToProduction',
      this.handlePartSentToProduction
    );
    if (this.supplierPartUUID) {
      this.getSupplierInternalPartProductionInformation();
    } else if (this.warehousePartUUID) {
      this.getSupplierPartProductionInformation();
    }
  },
  mounted() {},
  updated() {},
  beforeDestroy() {
    EventBus.$off(
      'SupplierInternalPartSentToProduction',
      this.handlePartSentToProduction
    );
  },
  methods: {
    handlePartSentToProduction() {
      this.allTechnologies = [];
      this.allMaterials = [];
      this.planningAndProductionParts = [];
      this.doneParts = [];
      this.donePartsByJob = [];
      if (this.supplierPartUUID) {
        this.getSupplierInternalPartProductionInformation();
      } else if (this.warehousePartUUID) {
        this.getSupplierPartProductionInformation();
      }
    },
    getSupplierPartProductionInformation() {
      this.$apiInstance
        .getSupplierPartProductionInformation(
          this.supplierUUID,
          this.warehousePartUUID
        )
        .then((data) => {
          this.productionInformations = data;
          this.analyseProductionInformation();
        });
    },
    getSupplierInternalPartProductionInformation() {
      this.$apiInstance
        .getSupplierInternalPartProductionInformation(
          this.supplierUUID,
          this.supplierPartUUID
        )
        .then((data) => {
          this.productionInformations = data;
          this.analyseProductionInformation();
        });
    },
    analyseProductionInformation() {
      const technologySet = new Set();
      const materialSet = new Set();
      for (const partInformation of this.productionInformations.planningAndProd) {
        if (!materialSet.has(partInformation.material)) {
          materialSet.add(partInformation.material);
          this.allMaterials.push({
            value: partInformation.material,
            text: this.$t(partInformation.material),
          });
        }
        if (!technologySet.has(partInformation.technology)) {
          technologySet.add(partInformation.technology);
          this.allTechnologies.push({
            value: partInformation.technology,
            text: this.$t(partInformation.technology),
          });
        }
        for (const order in partInformation.informationByOrder) {
          this.planningAndProductionParts.push({
            keyExpand:
              partInformation.printerModel +
              '_' +
              partInformation.printerBrand +
              '_' +
              partInformation.material +
              '_' +
              order,
            printerModel: partInformation.printerModel,
            printerBrand: partInformation.printerBrand,
            material: partInformation.material,
            technology: partInformation.technology,
            quantity: partInformation.informationByOrder[order].quantity,
            order: order,
            created: partInformation.informationByOrder[order].created,
            jobs: partInformation.informationByOrder[order].jobs,
          });
        }
      }

      for (const partInformation of this.productionInformations.archive) {
        if (!materialSet.has(partInformation.material)) {
          materialSet.add(partInformation.material);
          this.allMaterials.push({
            value: partInformation.material,
            text: this.$t(partInformation.material),
          });
        }
        if (!technologySet.has(partInformation.technology)) {
          technologySet.add(partInformation.technology);
          this.allTechnologies.push({
            value: partInformation.technology,
            text: this.$t(partInformation.technology),
          });
        }
        for (const order in partInformation.informationByOrder) {
          this.doneParts.push({
            keyExpand:
              partInformation.printerModel +
              '_' +
              partInformation.printerBrand +
              '_' +
              partInformation.material +
              '_' +
              order,
            printerModel: partInformation.printerModel,
            printerBrand: partInformation.printerBrand,
            material: partInformation.material,
            technology: partInformation.technology,
            quantity: partInformation.informationByOrder[order].quantity,
            order: order,
            created: partInformation.informationByOrder[order].created,
            jobs: partInformation.informationByOrder[order].jobs,
          });

          for (const jobInformation of partInformation.informationByOrder[order]
          .jobs) {
            this.donePartsByJob.push({
              printerModel: partInformation.printerModel,
              printerBrand: partInformation.printerBrand,
              material: partInformation.material,
              technology: partInformation.technology,
              quantity: partInformation.informationByOrder[order].quantity,
              order: order,
              created: jobInformation.created,
              jobNumber: jobInformation.jobNumber,
            });
          }
        }
      }
      this.filtersUpdate();
    },
    updateMinDate() {
      this.formMenuDateMin = false;
      this.formattedDateMin = this.formDateMin
        ? this.$d(new Date(this.formDateMin), 'numeric', this.$userLocale)
        : null;
      this.filtersUpdate();
    },
    updateMaxDate() {
      this.formMenuDateMax = false;
      this.formattedDateMax = this.formDateMax
        ? this.$d(new Date(this.formDateMax), 'numeric', this.$userLocale)
        : null;
      this.filtersUpdate();
    },
    verifyMinDate(date) {
      if (!this.formDateMin || !date) {
        return true;
      }
      const minDate = new Date(this.formDateMin).setDate(
        new Date(this.formDateMin).getDate()
      );
      return minDate <= new Date(date);
    },
    verifyMaxDate(date) {
      if (!this.formDateMax || !date) {
        return true;
      }
      const maxDate = new Date(this.formDateMax).setDate(
        new Date(this.formDateMax).getDate() + 1
      );
      return maxDate >= new Date(date);
    },
    filtersUpdate() {
      this.planningAndProductionPartsFiltered = [];
      this.donePartsFiltered = [];
      this.donePartsByJobFiltered = [];

      for (const partObj of this.planningAndProductionParts) {
        if (
          (this.formTechnologies.length === 0 ||
            this.formTechnologies.includes(partObj.technology)) &&
          (this.formMaterials.length === 0 ||
            this.formMaterials.includes(partObj.material)) &&
          this.verifyMinDate(partObj.created) &&
          this.verifyMaxDate(partObj.created)
        ) {
          this.planningAndProductionPartsFiltered.push(partObj);
        }
      }

      for (const partObj of this.doneParts) {
        if (
          (this.formTechnologies.length === 0 ||
            this.formTechnologies.includes(partObj.technology)) &&
          (this.formMaterials.length === 0 ||
            this.formMaterials.includes(partObj.material)) &&
          this.verifyMinDate(partObj.created) &&
          this.verifyMaxDate(partObj.created)
        ) {
          this.donePartsFiltered.push(partObj);
        }
      }

      for (const jobObj of this.donePartsByJob) {
        if (
          (this.formTechnologies.length === 0 ||
            this.formTechnologies.includes(jobObj.technology)) &&
          (this.formMaterials.length === 0 ||
            this.formMaterials.includes(jobObj.material)) &&
          this.verifyMinDate(jobObj.created) &&
          this.verifyMaxDate(jobObj.created)
        ) {
          this.donePartsByJobFiltered.push(jobObj);
        }
      }
    },
  },
};
</script>
