<template lang="html" src="./dataTableInternalPartArchive.template.vue">
  <!-- -->
</template>

<style lang="scss" src="./dataTableInternalPartArchive.scss"></style>

<script>
const i18nData = require('./dataTableInternalPartArchive.i18n');

export default {
  name: 'DataTableInternalPartArchive',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    supplierUUID: {
      type: String,
      required: true,
    },
    partsFiltered: {
      type: Array,
      required: true,
    },
    searchField: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      headersMain: [],
      headersSub: [],
      expandedRows: [],
    };
  },
  created() {
    this.headersMain = [
      {
        text: this.$t('ArchiveDate'),
        value: 'created',
        sortable: true,
      },
      {
        text: this.$t('JobNumber'),
        value: 'jobNumber',
        sortable: true,
        align: 'center',
      },
      {
        text: this.$t('Quantity'),
        value: 'quantity',
        sortable: true,
      },
      {
        text: this.$t('InternalOrderReference'),
        value: 'order',
        sortable: true,
      },
      { text: this.$t('Printer'), value: 'printer', sortable: false },
      {
        text: this.$t('Link'),
        value: 'link',
        align: 'center',
        sortable: false,
      },
    ];
  },
  mounted() {},
  updated() {},
  methods: {
    customSearch(value, search, item) {
      return Object.values(item).some(
        (v) => v && v.toString().toLowerCase().includes(search.toLowerCase())
      );
    },
  },
};
</script>
