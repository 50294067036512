var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-supplierInternalPartProduction"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "fill-height",
    attrs: {
      "cols": _vm.$vuetify.breakpoint.smAndDown ? 12 : 4
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "primary darken-1 white--text"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "white"
    }
  }, [_vm._v(" fas fa-filter ")]), _vm._v(" " + _vm._s(_vm.$t("Filters")) + " ")], 1), _c('v-card-text', {
    staticClass: "py-3"
  }, [_c('v-row', {
    staticClass: "mx-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('Search'),
      "append-icon": "fas fa-search",
      "messages": _vm.$t('SearchTips')
    },
    model: {
      value: _vm.searchField,
      callback: function callback($$v) {
        _vm.searchField = $$v;
      },
      expression: "searchField"
    }
  })], 1), _c('v-divider', {
    staticClass: "mt-6 mb-2"
  }), _c('v-row', {
    staticClass: "mx-0"
  }, [_c('v-select', {
    attrs: {
      "label": _vm.$t('Technologies'),
      "items": _vm.allTechnologies,
      "item-value": "value",
      "item-text": "text",
      "multiple": ""
    },
    on: {
      "change": _vm.filtersUpdate
    },
    model: {
      value: _vm.formTechnologies,
      callback: function callback($$v) {
        _vm.formTechnologies = $$v;
      },
      expression: "formTechnologies"
    }
  })], 1), _c('v-row', {
    staticClass: "mx-0"
  }, [_c('v-select', {
    attrs: {
      "label": _vm.$t('Materials'),
      "items": _vm.allMaterials,
      "item-value": "value",
      "item-text": "text",
      "multiple": ""
    },
    on: {
      "change": _vm.filtersUpdate
    },
    model: {
      value: _vm.formMaterials,
      callback: function callback($$v) {
        _vm.formMaterials = $$v;
      },
      expression: "formMaterials"
    }
  })], 1), _c('v-row', {
    staticClass: "mx-0"
  }, [_c('v-col', {
    staticClass: "pl-0 pr-4",
    attrs: {
      "cols": _vm.$vuetify.breakpoint.md ? 12 : 6
    }
  }, [_c('v-menu', {
    ref: "formMenuDateMin",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": _vm.$t('DateMinimum'),
            "readonly": "",
            "clearable": ""
          },
          on: {
            "click:clear": function clickClear($event) {
              _vm.formDateMin = null;
              _vm.formattedDateMin = null;

              _vm.filtersUpdate();
            }
          },
          model: {
            value: _vm.formattedDateMin,
            callback: function callback($$v) {
              _vm.formattedDateMin = $$v;
            },
            expression: "formattedDateMin"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.formMenuDateMin,
      callback: function callback($$v) {
        _vm.formMenuDateMin = $$v;
      },
      expression: "formMenuDateMin"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "show-current": false
    },
    on: {
      "input": _vm.updateMinDate
    },
    model: {
      value: _vm.formDateMin,
      callback: function callback($$v) {
        _vm.formDateMin = $$v;
      },
      expression: "formDateMin"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pr-0",
    class: {
      'pl-4': !_vm.$vuetify.breakpoint.md,
      'pr-0': !_vm.$vuetify.breakpoint.md,
      'pl-0': _vm.$vuetify.breakpoint.md
    },
    attrs: {
      "cols": _vm.$vuetify.breakpoint.md ? 12 : 6
    }
  }, [_c('v-menu', {
    ref: "formMenuDateMax",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": _vm.$t('DateMaximum'),
            "readonly": "",
            "clearable": ""
          },
          on: {
            "click:clear": function clickClear($event) {
              _vm.formDateMax = null;
              _vm.formattedDateMax = null;

              _vm.filtersUpdate();
            }
          },
          model: {
            value: _vm.formattedDateMax,
            callback: function callback($$v) {
              _vm.formattedDateMax = $$v;
            },
            expression: "formattedDateMax"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.formMenuDateMax,
      callback: function callback($$v) {
        _vm.formMenuDateMax = $$v;
      },
      expression: "formMenuDateMax"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "show-current": false
    },
    on: {
      "input": _vm.updateMaxDate
    },
    model: {
      value: _vm.formDateMax,
      callback: function callback($$v) {
        _vm.formDateMax = $$v;
      },
      expression: "formDateMax"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": _vm.$vuetify.breakpoint.smAndDown ? 12 : 8
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.panels,
      callback: function callback($$v) {
        _vm.panels = $$v;
      },
      expression: "panels"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "primary darken-1 py-2",
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "color": "white"
          }
        }, [_vm._v(" $expand ")])];
      },
      proxy: true
    }])
  }, [_c('v-col', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    staticClass: "mr-4",
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" fas fa-industry ")]), _vm._v(" " + _vm._s(_vm.$t("PlanningAndProduction")) + " ")], 1)], 1), _c('v-expansion-panel-content', {
    staticClass: "content-panel"
  }, [_c('DataTableInternalPartProduction', {
    attrs: {
      "supplier-u-u-i-d": _vm.supplierUUID,
      "parts-filtered": _vm.planningAndProductionPartsFiltered,
      "search-field": _vm.searchField,
      "archives": false
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "primary darken-1 py-2",
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "color": "white"
          }
        }, [_vm._v(" $expand ")])];
      },
      proxy: true
    }])
  }, [_c('v-col', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    staticClass: "mr-4",
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" fas fa-file-archive ")]), _vm._v(" " + _vm._s(_vm.$t("Done")) + " ")], 1), _c('v-btn-toggle', {
    staticClass: "mr-6",
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.archiveToggleButton,
      callback: function callback($$v) {
        _vm.archiveToggleButton = $$v;
      },
      expression: "archiveToggleButton"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_c('v-btn', _vm._g({
          nativeOn: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, on), [_c('v-icon', {
          attrs: {
            "color": "info"
          }
        }, [_vm._v(" fas fa-sticky-note ")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('GroupByOrder')))])]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on;
        return [_c('v-btn', _vm._g({
          staticClass: "d-flex",
          nativeOn: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, on), [_c('v-icon', [_vm._v("far fa-sticky-note")]), _c('v-icon', {
          staticClass: "error-times",
          attrs: {
            "small": "",
            "color": "error"
          }
        }, [_vm._v(" fas fa-times ")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('DontGroupByOrder')))])])], 1)], 1), _vm.archiveToggleButton === 0 ? _c('v-expansion-panel-content', [_c('DataTableInternalPartProduction', {
    attrs: {
      "supplier-u-u-i-d": _vm.supplierUUID,
      "parts-filtered": _vm.donePartsFiltered,
      "search-field": _vm.searchField,
      "archives": true
    }
  })], 1) : _c('v-expansion-panel-content', [_c('DataTableInternalPartArchive', {
    attrs: {
      "supplier-u-u-i-d": _vm.supplierUUID,
      "parts-filtered": _vm.donePartsByJobFiltered,
      "search-field": _vm.searchField
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }