var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-dataTableInternalPartArchive"
  }, [_c('v-data-table', {
    attrs: {
      "search": _vm.searchField,
      "custom-filter": _vm.customSearch,
      "headers": _vm.headersMain,
      "items": _vm.partsFiltered,
      "sort-by": "created",
      "sort-desc": true,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      },
      "item-key": "keyExpand"
    },
    scopedSlots: _vm._u([{
      key: "item.created",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "x-small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-calendar-alt ")]), item.created ? _c('span', [_vm._v(_vm._s(_vm.$d(new Date(item.created), "long")))]) : _c('span', {
          staticClass: "font-weight-light"
        }, [_vm._v("- " + _vm._s(_vm.$t("Unknown")))])];
      }
    }, {
      key: "item.printer",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "x-small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-print ")]), _c('span', [_vm._v(" " + _vm._s(item.printerBrand.replace('_', ' ')) + " - " + _vm._s(item.printerModel.replace('_', ' ')) + " ( ")]), _c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(_vm.$t(item.material)))]), _c('span', [_vm._v(")")])];
      }
    }, {
      key: "item.order",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "x-small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-sticky-note ")]), item.order !== '' ? _c('span', [_vm._v(_vm._s(item.order))]) : _c('span', {
          staticClass: "font-weight-light"
        }, [_vm._v("- " + _vm._s(_vm.$t('None')))])];
      }
    }, {
      key: "item.quantity",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "x-small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-cubes ")]), _c('span', [_vm._v(_vm._s(item.quantity))])];
      }
    }, {
      key: "item.link",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "color": "info",
            "href": "/#/".concat(_vm.supplierUUID, "/production/printer/brand/").concat(item.printerBrand, "/printer/model/").concat(item.printerModel, "?tab=ARCHIVES&material=").concat(item.material, "&jobNumber=").concat(item.jobNumber)
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-external-link-square-alt ")])], 1)];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }