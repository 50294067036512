<template lang="html" src="./dataTableInternalPartProduction.template.vue">
  <!-- -->
</template>

<style lang="scss" src="./dataTableInternalPartProduction.scss"></style>

<script>
const i18nData = require('./dataTableInternalPartProduction.i18n');

export default {
  name: 'DataTableInternalPartProduction',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    supplierUUID: {
      type: String,
      required: true,
    },
    partsFiltered: {
      type: Array,
      required: true,
    },
    archives: {
      type: Boolean,
      required: true,
    },
    searchField: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      headersMain: [],
      headersSub: [],
      expandedRows: [],
    };
  },
  created() {
    this.headersMain = [
      { text: this.$t('Printer'), value: 'printer', sortable: false },
      {
        text: this.$t('InternalOrderReference'),
        value: 'order',
        sortable: true,
      },
      {
        text: this.$t('TotalQuantity'),
        value: 'quantity',
        sortable: true,
        align: 'start',
      },
      {
        text: this.$t('Created'),
        value: 'created',
        align: 'center',
        sortable: false,
      },
      { text: this.$t('Jobs'), value: 'data-table-expand', align: 'center' },
    ];
    this.headersSub = [
      this.archives
        ? {
          text: this.$t('ArchiveDate'),
          value: 'created',
          sortable: false,
          align: 'center',
        }
        : {
          text: this.$t('Status'),
          value: 'status',
          sortable: false,
          align: 'center',
        },
      {
        text: this.$t('JobNumber'),
        value: 'jobNumber',
        sortable: true,
        align: 'center',
      },

      {
        text: this.$t('Quantity'),
        value: 'quantity',
        sortable: true,
      },
      {
        text: this.$t('Link'),
        value: 'link',
        align: 'center',
        sortable: false,
      },
    ];
  },
  mounted() {},
  updated() {},
  methods: {
    customSearch(value, search, item) {
      return Object.values(item).some(
        (v) => v && v.toString().toLowerCase().includes(search.toLowerCase())
      );
    },
    getHRef(item, itemChild) {
      if (itemChild.status === 'WAITING') {
        return `/#/${this.supplierUUID}/production/printer/brand/${item.printerBrand}/printer/model/${item.printerModel}`;
      } else {
        return `/#/${this.supplierUUID}/production/printer/brand/${item.printerBrand}/printer/model/${item.printerModel}?tab=${itemChild.status}&material=${item.material}&jobNumber=${itemChild.jobNumber}`;
      }
    },
  },
};
</script>
